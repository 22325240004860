import axios from "axios";
import { useState } from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Section10 = (props) => {
  const MySwal = withReactContent(Swal);

  const [contactInput, setContactInput] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInput = (e) => {
    e.persist();
    setContactInput({ ...contactInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ContactDate = {
      name: contactInput.name,
      email: contactInput.email,
      subject: contactInput.subject,
      message: contactInput.message,
    };

    const answer = window.confirm("送信しますか？");

    if (answer) {
      await axios
        .post(
          "https://www.addinback.addin-global.com/api/send/email",
          ContactDate
        )
        .then((res) => {
          MySwal.fire({
            title: <strong>送信完了！</strong>,
            html: (
              <i>
                お問合せありがとうございました。
                <br />
                24時間以内にお返事致しますのでもうしばらくお待ち下さい。
              </i>
            ),
            icon: "success",
          });
        });

      setContactInput({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <>
      <div
        className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24"
        ref={props.ContactRef}
      >
        <div className="relative mx-auto max-w-xl">
          <svg
            className="absolute left-full translate-x-1/2 transform"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 -translate-x-1/2 transform"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <h2 className="text-lg font-semibold text-teal-500">
              お問い合わせ
            </h2>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Contact Us
            </h2>
          </div>
          <div className="mt-12">
            <form
              method="POST"
              onSubmit={handleSubmit}
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  お名前
                  <span className="text-red-400 ml-1">※</span>
                </label>
                <div className="mt-1 sm:hover:scale-105 duration-300">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={contactInput.name}
                    onChange={handleInput}
                    required="required"
                    className="block w-full rounded-md border-gray-500 py-3 px-4 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  メールアドレス
                  <span className="text-red-400 ml-1">※</span>
                </label>
                <div className="mt-1 sm:hover:scale-105 duration-300">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={contactInput.email}
                    onChange={handleInput}
                    required="required"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-gray-700"
                >
                  件名
                  <span className="text-red-400 ml-1">※</span>
                </label>
                <div className="mt-1 sm:hover:scale-105 duration-300">
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    value={contactInput.subject}
                    onChange={handleInput}
                    required="required"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  お問合せ内容
                  <span className="text-red-400 ml-1">※</span>
                </label>
                <div className="mt-1 sm:hover:scale-105 duration-300">
                  <textarea
                    id="message"
                    name="message"
                    rows={10}
                    value={contactInput.message}
                    onChange={handleInput}
                    required="required"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-teal-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 sm:hover:scale-105 duration-300"
                >
                  送信しますか？
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section10;
