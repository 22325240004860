import AOS from "aos";
import "aos/dist/aos.css";
import FsLightbox from "fslightbox-react";
import React, { useEffect, useState } from "react";

import item1 from "../../assets/images/item-1.png";
import item2Big from "../../assets/images/item-2-big.png";
import item2 from "../../assets/images/item-2.png";
import item3Big from "../../assets/images/item-3-big.png";
import item3 from "../../assets/images/item-3.png";

const Section8 = () => {
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <div data-aos="fade-down-right">
            <h2 className="text-left text-lg font-semibold text-teal-500">
              メディア掲載{" "}
              <span className="text-sm pl-1">
                Quick問診(商標出願2022-031237)
              </span>
            </h2>
            <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Media Coverage
            </h3>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
          <div
            className="group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2"
            data-aos="zoom-out"
          >
            <a
              href="https://www.qa-quick.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={item1}
                alt="item-1"
                className="object-cover object-center group-hover:opacity-50"
              />
            </a>
            <div
              aria-hidden="true"
              className="bg-gradient-to-b from-transparent to-black opacity-50"
            />
            <p className="text-teal-500 text-xs">※ 外部サイトへ移動します。</p>
          </div>

          <button onClick={() => setToggle1(!toggle1)}>
            <div
              className="group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-none relative sm:h-full"
              data-aos="zoom-out"
            >
              <img
                src={item2}
                alt="item-2"
                className="object-cover object-center group-hover:opacity-50 transition-all durations-300 sm:absolute sm:inset-0 sm:h-full sm:w-full"
              />

              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                <div className="flex-row text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-16 h-auto text-teal-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </button>

          <FsLightbox
            toggler={toggle1}
            sources={[item2Big]}
            nitialAnimation="scale-in-long"
            slideChangeAnimation="scale-in"
          />

          <button onClick={() => setToggle2(!toggle2)}>
            <div
              className="group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-none relative sm:h-full"
              data-aos="zoom-out"
            >
              <img
                src={item3}
                alt="item-3"
                className="object-cover object-center group-hover:opacity-50 transition-all durations-300 sm:absolute sm:inset-0 sm:h-full sm:w-full"
              />

              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                <div className="flex-row text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-16 h-auto text-teal-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </button>

          <FsLightbox
            toggler={toggle2}
            sources={[item3Big]}
            nitialAnimation="scale-in-long"
            slideChangeAnimation="scale-in"
          />
        </div>
      </div>
    </div>
  );
};

export default Section8;
