import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import client1 from "../../assets/images/client1.jpeg";
import client2 from "../../assets/images/client2.jpg";
import client3 from "../../assets/images/client3.jpg";

const posts = [
  {
    id: 1,
    imageUrl: client1,
    companyName: "Beginning fitness 様",
    link: "/client_1",
  },
  {
    id: 2,
    imageUrl: client2,
    companyName: "株式会社 廣洋社 様",
    link: "/client_2",
  },
  {
    id: 3,
    imageUrl: client3,
    companyName: "一般社団法人 健康促進開発機構 様",
    link: "/client_3",
  },
];

const Section7 = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div
      className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28"
      ref={props.ClientRef}
    >
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center" data-aos="fade-down-left">
          <h2 className="text-center text-lg font-semibold text-teal-500">
            クラアント様の声
          </h2>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Client Testimonials
          </h3>
        </div>
        <div className="mx-auto mt-10 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              data-aos="zoom-out"
            >
              <button onClick={() => navigate(post.link)}>
                <div className="relative group flex-shrink-0">
                  <img
                    className="h-80 w-full object-cover"
                    src={post.imageUrl}
                    alt="results"
                  />
                  <div className="flex justify-center items-center opacity-0 bg-gradient-to-t from-teal-500 via-teal-500 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full transition-all durations-300 "></div>
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
                    <div className="flex-row text-center">
                      <h1 className="text-gray-50 text-lg">
                        {post.companyName}
                      </h1>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section7;
