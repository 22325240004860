const items = [
  {
    id: 1,
    title: "2000年01月　フットレストADDIN創業",
  },
  {
    id: 2,
    title: "2006年07月　株式会社フットレスト設立",
  },
  {
    id: 3,
    title: "2006年12月　自社顧客管理・予約システム開発・運用開始",
  },
  {
    id: 4,
    title: "2010年08月　医療機関（クリニック）の立ち上げにコンサルとして従事",
  },
  {
    id: 5,
    title: "2010年10月　同医療機関にて、業務管理・健康診断管理システム開発",
  },
  {
    id: 6,
    title: "2012年02月　インドにてオフショアシステム開発開始",
  },
  {
    id: 7,
    title:
      "2016年09月　社団法人向け ロコモ度チェック管理システム開発（予防医療）",
  },
  {
    id: 8,
    title: "2019年03月　一般社団法人向け血液診断システム開発",
  },
  {
    id: 9,
    title: "2020年11月　歯科業界向け非接触型問診票開発",
  },
  {
    id: 9,
    title: "2023年02月　株式会社ADDIN設立",
  },
];

const AlongTheLine = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Our History
            </h2>
            <p className="text-lg text-teal-500">沿線</p>
          </div>
          <div className="lg:col-span-2">
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-1 sm:gap-12 sm:space-y-0 lg:gap-x-8"
            >
              {items.map((item) => (
                <li key={item.id}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <div className="space-y-1 text-lg font-medium leading-6">
                      <h3>{item.title}</h3>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlongTheLine;
