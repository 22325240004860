import icon1 from "../../assets/icons/icon1.png";
import icon2 from "../../assets/icons/icon2.png";
import icon3 from "../../assets/icons/icon3.png";
import icon4 from "../../assets/icons/icon4.png";

const incentives = [
  {
    name: "品質",
    description:
      "打ち合わせから専任SEが責任を持ってご担当させて頂き、開発チームメンバーと共有します。",
    imageSrc: icon1,
  },
  {
    name: "レスポンシブデザイン",
    description: "Bootstrap,Parallaxなどのフレームワークを使用します。",
    imageSrc: icon2,
  },
  {
    name: "視覚化",
    description: "現場でのボトルネックを追求改善ながら可視化を実現します。",
    imageSrc: icon3,
  },
  {
    name: "オリジナリティ",
    description:
      "オリジナルなCSMをはじめ、ユーザービリティに配慮した操作性を確保します。",
    imageSrc: icon4,
  },
];

const Section1 = () => {
  return (
    <>
      {/* Logo Cloud */}
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              What we do？
            </p>
            <p className="mx-auto mt-5 max-w-5xl text-left text-xl text-gray-500">
              サービス業種分野から、医療分野まで幅広く対応しています。
              クライアント様（特にシステムを使用する現場の方々）の状況を把握し、業務の簡素化を図りオペレーションの向上にフォーカスします。
            </p>
          </div>
        </div>
      </div>

      {/* Alternating Feature Sections */}
      <div className="bg-gray-50">
        <div className="mx-auto max-w-2xl py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
            {incentives.map((incentive) => (
              <div key={incentive.name}>
                <img src={incentive.imageSrc} alt="" className="h-10 w-auto" />
                <h3 className="mt-6 text-base font-medium text-gray-900">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-base text-gray-500">
                  {incentive.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
