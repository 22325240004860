import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import results1 from "../../assets/images/results1.jpg";
import results2 from "../../assets/images/results2.gif";
import results3 from "../../assets/images/results3.png";
import results4 from "../../assets/images/results4.png";

const posts = [
  {
    title: "サロン予約システム",
    description:
      "【お客様】⇔【店舗】⇔【スタッフ】の３者を連動させ一連の業務をシステム内で完結致します。",
    imageUrl: results1,
    aos: "fade-up",
  },
  {
    title: "健康診断管理システム",
    description:
      "健康診断管理をクラウド化する事により、今までに出来なかったさまざまな環境作りが可能となります。",
    imageUrl: results2,
    aos: "fade-right",
  },
  {
    title: "血液検査分析",
    description:
      "血液検査結果をもとに、医療機関提供の生活習慣改善法や未病に役立つ情報を提供します。",
    imageUrl: results3,
    aos: "fade-down",
  },
  {
    title: "ロコモ度チェック",
    description:
      "医療機関が推奨する測定基準に伴い、身体能力の判定を行い元気なカラダを維持します。",
    imageUrl: results4,
    aos: "fade-left",
  },
];

const Section4 = (props) => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div
      className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28"
      ref={props.AchievementsRef}
    >
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center" data-aos="fade-down-left">
          <h2 className="text-center text-lg font-semibold text-teal-500">
            開発実績例
          </h2>
          <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Development Achievements
          </h3>
        </div>
        <div className="mx-auto mt-10 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-4">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              data-aos={post.aos}
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.imageUrl}
                  alt="results"
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {post.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section4;
