import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import plan from "../../assets/images/plan.gif";

const faqs = [
  {
    id: 1,
    question: "サービス業種以外でもシステム開発は可能？",
    answer:
      "サービス業種のシステム開発を得意としておりますが、業務管理や在庫管理システムなどの開発実績もあります。基本的にシステム開発は、クライアント様の財産である「情報」をいかに効率よく活用し共有できるか？と考えています。その財産（情報）の活用に対しての導線をイメージすると同時にボトルネックを改善し今まで見えなかった部分を表面化して業務の改善に役立てる事を得意としています。",
  },
  {
    id: 2,
    question: "既存システムの修正・変更は可能？",
    answer:
      "他ベンダーさんが開発したシステムでも、修正・変更は可能です。\n" +
      "また、仕様書（設計書）が無い場合でもソースコードを解読し、ご要望に応じて仕様変更なども賜り開発修正いたします。",
  },
  {
    id: 3,
    question: "サービスイン後の保守管理は可能？",
    answer:
      "自社サーバー、他社サーバーに関係なく保守管理も行います。また、CMSなどのプラットフォームをご希望された場合でも、クライアント様が今まで操作に馴れた環境を維持構築します。必要に応じて、ユーザービリティに優れたオリジナルCMSの開発も行います。",
  },
  {
    id: 4,
    question: "ホームページも作成可能？",
    answer:
      "専属のUIデザイナーも在籍していますのでご対応可能です。また、JavaScript（Jquery）などを多用した動的UIも得意分野です。目を引くホームページのアニメーション化で視覚的インセンティブを発生させます。SEO対策に適したコーディングにも配慮しています。",
  },
  {
    id: 5,
    question: "開発実績の規模感はどれくらい？",
    answer:
      "従業員様が少数～100名規模のクライアント様の開発実績がメインとなります。開発費のご予算に応じて臨機応変な対応を心がけていますので、初期段階でのご予算をお聞きした上で開発手法などのご提案をいたします。（納期も3～6ヶ月規模の開発となります。）\n" +
      "開発ボリュームが大きいが開発費に予算をかけれない場合でも、ご相談いただければボリューム感を損なうことなく予算内でのリソース提案も可能です。但し上限がありますのでご希望に添えない場合もございます。",
  },
  {
    id: 6,
    question: "多言語化にも対応可能？",
    answer:
      "日本語⇔英語⇔中国語⇔ベトナム語⇔ヒンディー語など、多種類に渡り可能です。クライアント様の業種に応じた専門用語や日本語とは異なるニュアンスを持つ外国語（現地国コピーライターへ依頼し、母国語に対しての違和感を払拭する事でメッセージの訴求力を向上させます）にも対応可能です。",
  },
  {
    id: 7,
    question: "なぜ、安価な開発の提案が可能？",
    answer:
      "クライアント様とのやり取りは全て自社スタッフでご対応致しますが、システム開発で一番工数のかかるプログラミングをオフショア（インド）で行う事により開発費が抑えられます。オフショア開発のデメリットである、「日本語が通じない」や「アフターは大丈夫？」などは全て国内生産となります。言わば、日本国内での慢性的なプログラマー不足の部分のみをオフショアで行います。\n" +
      "また、全て国内生産のご希望であれば責任を持ってご対応致します。\n" +
      "\n" +
      "オフショア開発の作業分担図をご参考下さい。\n" +
      "↓↓↓",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Section9 = (props) => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className="bg-gray-50" ref={props.FAQRef}>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <div className="text-center" data-aos="fade-up">
            <h2 className="text-center text-lg font-semibold text-teal-500">
              よくある質問
            </h2>
            <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Frequently Asked Questions
            </h3>
          </div>
          <dl
            className="mt-6 space-y-6 divide-y divide-gray-200"
            data-aos="fade-up"
          >
            {faqs.map((faq) => (
              <Disclosure
                as="div"
                key={faq.id}
                className="pt-6"
                data-aos="fade-up"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                      {faq.id === 7 ? (
                        <img className="mt-5" src={plan} alt="plan" />
                      ) : (
                        ""
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Section9;
