import TopImagePic from "../assets/images/topImage.jpg";
// import videoBg from "../assets/videos/office.mp4";

const TopImage = (props) => {
  return (
    <>
      {/* Hero section */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="mx-auto max-w-full sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
            <div className="absolute inset-0">
              <img
                className="h-full w-full contrast-50 brightness-90 object-cover"
                src={TopImagePic}
                alt="companyImage"
              />
              {/* <video
                className="h-full w-full contrast-50 brightness-90 object-cover"
                src={videoBg}
                autoPlay
                loop
                muted
              /> */}
              <div className="absolute inset-0 backdrop-blur-sm bg-white/30 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl sm:animate-text-flicker-in-glow">
                <span className="block text-white">We take your business</span>
                <span className="block text-teal-400">to the next level</span>
              </h1>
              <p className="mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl">
                私たちはサービス業分野を得意とする、システム開発会社です。
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopImage;
