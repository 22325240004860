import {useNavigate} from "react-router-dom";
import logo from "../../../assets/images/addin.png";

const HeaderClient = () => {
  const navigate = useNavigate();
  return (
      <header className="sticky top-0 z-10 bg-white">
        <div
            className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img
                  className="h-8 w-auto sm:h-10"
                  src={logo}
                  alt=""
              />
            </a>
          </div>
          <button onClick={() => {
            navigate(-1)
          }}
                  className="text-base font-medium text-gray-500 hover:text-gray-900">
            クライアント様の声一覧
          </button>
        </div>
      </header>
  )
}

export default HeaderClient;
