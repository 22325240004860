import HeaderClient from "./HeaderClient";
import image from "../../../assets/images/kuroda.jpg";

const messages = [
  {
    id:1,
    title: '-「Me チェック」の開発経緯を教えてください。',
    message: '世界最大の食品メーカーであるクライアント企業がクリニックでサプリメントを販売することになり、弊社が依頼を受けたことがきっかけです。 依頼内容は、血液検査の数値を入れることによって、クリニックに来られた方それぞれ に必要な栄養素がわかる仕組みが欲しい、ということでしたが、ちょうど株式会社フットレストADD INさんの「B ナビ」の仕組みがそれに近かったので、カスタマイズして導入に至りました。',
  },
  {
    id:2,
    title: '-具体的にはどのようなカスタマイズを行いましたか?',
    message: '血液検査などの数値を入力する仕組みは「B ナビ」と同じですが、約 40 項目それぞれ の基準値から外れた場合にアドバイスが表示されるようになっています。基準値から外れるとどのような懸念があるのか、どんなことに気をつけなければいけないのか、どんな栄養素が必要なのか、といった内容が明確に表示されます。 それに加え、項目毎に必要な栄養素が重複する場合に、優先順位が出るようになってい ます。個人個人に合わせて優先順位をつけて表示される仕組みは「B ナビ」にはない点 です。',
  },
  {
    id:3,
    title: '-「Me チェック」はクリニック等では実際にどのように活用される予定ですか?',
    message: 'クリニックで「Me チェック」を使って明示する不足している栄養素に対し、推奨され るサプリメントが何かがわかる仕組みを、当該クライアントが独自に開発しています。 「Me チェック」とそのシステムを使ってその人に最適なサプリを提案する、というこ とですね。 患者さんにとっては非常にわかりやすいですし、クリニックにとっても良いサービスの 提供に繋がると思います。今後色々なクリニックで導入されていく予定です。',
  },
  {
    id:4,
    title: '-開発を依頼する中で大変だったと感じる点はありますか?',
    message:
        '株式会社フットレストADD INさんの技術が素晴らしく、短期間で全ての要望に対応していただいたので、 技術的な部分ではこちらが苦労を感じることはありませんでした。' +
        '「Me チェック」で表示される内容は医師の診断になるので、その文言をドクターに書いてもらい監修するという流れで行いました。どういった診断内容にするか、という点は大変だったように思います。実際に約 40 項目の内容についてドクターに診断の文言を作っていただいたき、校正をしていきました。' +
        '成分についても約 40 以上あるので、ドクターにとっても患者さんにとってもわかりやすい表現をするという点も簡単ではありませんでした。一般の方に難しい言葉を並べても理解できないですし、ドクターからすると簡単すぎてもいけないので、両者がスムーズに読める内容にするよう心がけました。',
  },
  {
    id:5,
    title: '-クライアントさんの評価はいかがでしたか?',
    message: '当初、クライアントとシステムの仕様を決める際に、理想と現実の落とし所を決めることに多少時間を要しました。ただその後の流れはスムーズで、非常に満足いただいてい ます。納品後、実用してみて出てくる改良点については、株式会社フットレストADD INさんに即座に対 応していただけたのでとても助かりました。 クライアントの要望を受け、株式会社フットレストADD INさんに相談をすると、ユーザーの意見を考慮した上で、さらにより良い提案をいただいて、どんどんいい方向に改善しています。ですので、クライアントの満足度もかなり高いです。',
  },
  {
    id:6,
    title: '-株式会社フットレストADD INの対応についてはいかがですか?',
    message: 'こちらの細かい要望に対し、提案をしつつさらにより良い方向へと対応してくれました。 そして納期は必ず守っていただき、弊社もとても信頼しています。技術と信頼のある企業だと思います。何かあればまたぜひお願いしたいと思っています。',
  },
]

const Client2 = () => {
  return (
      <>
        <HeaderClient/>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-5xl text-lg">
            <h1>
              <span className="block text-center text-lg font-semibold text-teal-600">Introducing</span>
              <span
                  className="mt-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
              株式会社 廣洋社 | 代表取締役社長 黒田様
            </span>
            </h1>
            <p className="mt-8 text-center text-xl leading-8 text-gray-500">
              ドクターと患者、両者にとってより良いシステムを目指して
            </p>
            <p className="mt-8 text-md leading-8 text-gray-500">
              広告会社の枠を超え「エンターテインメント」「ダイレクトマーケティング」「医療 美容」の３つの分野におけるビジネス支援会社として歩みを進める株式会社廣洋社。
              今回は株式会社廣洋社が手がける「Me チェック」システムについて、黒田武嗣社長にお話を聞きました。
            </p>
            <div className="flex justify-center mx-auto my-7 max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                      className="rounded-lg object-cover object-center shadow-lg"
                      src={image}
                      alt="Whitney leaning against a railing on a downtown street"
                  />
                </div>
              </figure>
            </div>

            {messages.map((message) => {
              return (
                  <div className="my-10" key={message.id}>
                    <p className='text-lg text-left leading-8 text-teal-600'>
                      {message.title}
                    </p>
                    <p className="mt-3 text-md text-left leading-8 text-gray-500">
                      {message.message}
                    </p>
                  </div>
              )
            })}

            <div className="flex py-5">
              <p>
                株式会社 廣洋社 様 ホームページ&emsp;
              </p>
              <a className="text-cyan-500 underline" href="http://www.kys-net.co.jp" target="_blank" rel="noreferrer">
                http://www.kys-net.co.jp
              </a>
            </div>

          </div>
        </div>
      </>
  )
}

export default Client2;
