import {
  ArrowsRightLeftIcon,
  CheckIcon,
  ComputerDesktopIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/outline";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import phoneImg from "../../assets/images/iphone.png";

const transferFeatures = [
  {
    id: 1,
    name: "プラットフォームサポート",
    description:
      "ユーザーの環境を選ばないマルチプラットフォームで、スムーズで快適な環境を構築します。",
    icon: ComputerDesktopIcon,
  },
  {
    id: 2,
    name: "プロトタイピングツール",
    description:
      "デザインイメージをご要望に応じてプロトタイプします。イメージを明確にし画面遷移やアニメーションなどの動きをチェック。",
    icon: ArrowsRightLeftIcon,
  },
  {
    id: 3,
    name: "良質なデザイン",
    description:
      "最新のStyleやライブラリーでバックエンドを構築し、フロントエンドはシンプルで、迷いのない操作性を実現します。",
    icon: CheckIcon,
  },
  {
    id: 4,
    name: "分かりやすく",
    description:
      "クライアントの属性に配慮した画面デザインの採用で、老若男女を問わないシンプルデザインを具現化します。",
    icon: PuzzlePieceIcon,
  },
];

const Section3 = () => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
      <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative" data-aos="fade-down-right">
          <h2 className="text-center text-lg font-semibold text-teal-500">
            良質なデザイン保証
          </h2>
          <h3 className="mt-2 text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            High Quality Design Guarantee
          </h3>
          <p className="mx-auto mt-5 max-w-5xl text-left text-xl text-gray-500">
            サービス業種分野から、医療分野まで幅広く対応しています。
            クライアント様（特にシステムを使用する現場の方々）の状況を把握し、業務の簡素化を図りオペレーションの向上にフォーカスします。
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <dl className="space-y-10">
              {transferFeatures.map((item) => (
                <div
                  key={item.id}
                  className="relative"
                  data-aos="fade-up-right"
                >
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-teal-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto w-auto h-96"
              data-aos="fade-left"
              width={490}
              src={phoneImg}
              alt=""
            />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>
      </div>
    </div>
  );
};

export default Section3;
