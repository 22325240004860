import {useRef} from "react";

import Header from "./pages/Header";
import Section1 from "./pages/Section1";
import TopImage from "./TopImage";
import Section2 from "./pages/Section2";
import Section3 from "./pages/Section3";
import Section4 from "./pages/Section4";
import Section5 from "./pages/Section5";
import Section6 from "./pages/Section6";
import Section7 from "./pages/Section7";
import Section8 from "./pages/Section8";
import Section9 from "./pages/Section9";
import Section10 from "./pages/Section10";

const Main = () => {

  const AchievementsRef = useRef(null);
  const ClientRef = useRef(null);
  const FAQRef = useRef(null);
  const ContactRef = useRef(null);

  const ScrollToAchievements = () => AchievementsRef.current.scrollIntoView(({behavior: 'smooth', block: 'start'}));
  const ScrollToClient = () => ClientRef.current.scrollIntoView(({behavior: 'smooth', block: 'start'}));
  const ScrollToFAQ = () => FAQRef.current.scrollIntoView(({behavior: 'smooth', block: 'start'}));
  const ScrollToContact = () => ContactRef.current.scrollIntoView(({behavior: 'smooth', block: 'start'}));

  return (
      <>

        <Header
            ScrollToAchievements={ScrollToAchievements}
            ScrollToClient={ScrollToClient}
            ScrollToFAQ={ScrollToFAQ}
            ScrollToContact={ScrollToContact}
        />

        <main className="overflow-hidden">

          <TopImage/>

          <Section1/>

          <Section2/>

          <Section3/>

          <Section4 AchievementsRef={AchievementsRef}/>

          <Section5/>

          <Section6/>

          <Section7 ClientRef={ClientRef}/>

          <Section8/>

          <Section9 FAQRef={FAQRef}/>

          <Section10 ContactRef={ContactRef}/>

        </main>

      </>
  );
};

export default Main;
