import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Client1 from './components/pages/Clients/Client1';
import Client2 from "./components/pages/Clients/Client2";
import Client3 from "./components/pages/Clients/Client3";
import ScrollToTop from "./components/pages/Clients/ScrollTop";
import NotFound from './components/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<App/>}/>
          <Route path="/client_1" element={<Client1/>}/>
          <Route path="/client_2" element={<Client2/>}/>
          <Route path="/client_3" element={<Client3/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
