import Main from './components/Main';
import Footer from "./components/Footer";
import ScrollToTop from 'react-scroll-to-top';

const App = () => {
  return (
      <>
        <Main/>
        <Footer/>
        <ScrollToTop smooth component={<p style={{ color: "teal" }}>↑</p>} />
      </>
  )
}

export default App;
