import HeaderClient from "./HeaderClient";
import image from "../../../assets/images/imai.jpg";

const messages = [
  {
    id: 1,
    title: '-「Bnavi」の開発経緯を教えてください。',
    message: '血液検査のデータを元に、健康づくりや生活習慣の改善に役立つ情報を発信するシステム「Bnavi」ですが、元々、株式会社フットレストADD INさんでそのベースとなる商品の開発が終わっていました。それを販売に結び付けられないか、とご相談をいただいたのがきっかけで す。 当初の商品は非常に難しい内容で、ドクターは理解できても、患者さんにはわかりにくいものでした。より詳しく、と開発を進めた結果かなり煩雑になってしまっていたのだ と思います。 そこで、もっと徹底的に簡単に、ドクターにも患者さんにもわかりやすく、利用しやす いものへと再開発を行いました。',
  },
  {
    id: 2,
    title: '-血液検査の結果を有効に活用するシステムを構築したということですね。',
    message: '血液検査の結果について、実際にドクターから具体的なアドバイスを得る機会は少ない かもしれませんが、とても重要なデータです。 ドクターはその結果によって、診断し治療を行います。検査結果から導かれる食生活のアドバイスは管理栄養士の領域ですが、我々(一社)健康促進開発機構としては、この結果を活用して、ドクターに予防に関するアドバイスまで行ってほしいと思っています。',
  },
  {
    id: 3,
    title: '-「Bnavi」は具体的にどのように活用されますか?',
    message: '血液検査の結果から分析した内容をクリニックに届け、ドクターはその内容を確認、そ れを元に患者さんにアドバイスを行うという流れです。異常値が出ている場合、どんなリスクがあるのか、基準値内に収めるにはどの栄養素が必要か、などデータとして具体的に表示されます。患者さんは自分のデータをスマホで簡単に確認できアドバイス を可視化できます。',
  },
  {
    id: 4,
    title: '-開発を進める中で、印象に残っていることはありますか?',
    message: 'このシステムを知ったドクターからは「すごいシステムだ」と驚愕されました。 一方で、診断内容の記述については誰が書いたのか？という声もありました。このシステムで出されるアドバイスは、一般的な基準値を採用し、あくまでも(一社) 健康促進開発機構の考え方として、提示しています。ドクターのコメントを入力できるようにしているので、アドバイスを追記して、カスタマイズも可能です。',
  },
  {
    id: 5,
    title: '-開発を進める中で大変だったと感じることを教えてください。',
    message: 'わかりやすく簡単に、とにかくシンプルにするという作業に多く時間を要しました。 より詳しくすることで複雑になってしまっていたものを、少しずつ削っていくのは、予想以上に難しかったように思います。 ただ、株式会社フットレストADD INさんとのやり取りで大変だと感じる部分はありませんでした。 営業上の問題や開発上の問題、これらをお互いが理解しようとするメンタリテ ィーが基本ですので、その点が我々の間で共有できていたということでしょう。 互いの役割を明確に、進めることができました。 その分、議論は大いにありました。当然ですが、株式会社フットレストADD INさんは開発者としてきめ細かいソフトを提供しようという姿勢で取り組んで頂きました。ただ、そこには落とし穴があって、あまりにも細か過ぎて、使い手には難しくなってしまう傾向が出てきたん です。その部分を軌道修正するのが私の役割で、それを快く受け入れてくれたことに感 謝しています。',
  },
  {
    id: 6,
    title: '-全体を通してフットレストの対応はいかがでしたか?',
    message: '株式会社フットレストADD INさんは元々医療関係のソフトウェア開発に長けているため、スムーズに進行でき、全く問題はありませんでした。お互いの役割を自然と理解し合えていたため、開発のプロセスで前向きな意見交換もで き、目的に対する互いのモチベーションを維持できたと思います。',
  },
]

const Client3 = () => {
  return (
      <>
        <HeaderClient/>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-5xl text-lg">
            <h1>
              <span className="block text-center text-lg font-semibold text-teal-600">Introducing</span>
              <span
                  className="mt-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
              一般社団法人 健康促進開発機構 |代表理事 今井義博様
            </span>
            </h1>
            <p className="mt-8 text-center text-xl leading-8 text-gray-500">
              ベースを活かして、わかりやすくシンプルなシステムを開発
            </p>
            <p className="mt-8 text-md leading-8 text-gray-500">

              医療資源の有効活用を目的に、生活習慣や健康管理に有効な健康システムを開発し、提供している一般社団法人健康促進開発機構。
              今回は(一社)健康促進開発機構が提供するサービス「Bnavi」システムについて、当機構の代表理事で、医療歯科クリニックの開発支援とプロデュースを手がける今井義博
              さんにお話を聞きました。
            </p>
            <div className="flex justify-center mx-auto my-7 max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                      className="rounded-lg object-cover object-center shadow-lg"
                      src={image}
                      alt="Whitney leaning against a railing on a downtown street"
                  />
                </div>
              </figure>
            </div>

            {messages.map((message) => {
              return (
                  <div className="my-10" key={message.id}>
                    <p className='text-lg text-left leading-8 text-teal-600'>
                      {message.title}
                    </p>
                    <p className="mt-3 text-md text-left leading-8 text-gray-500">
                      {message.message}
                    </p>
                  </div>
              )
            })}

            <div className="flex py-5">
              <p>
                一般社団法人 健康促進開発機構 様 ホームページ&emsp;
              </p>
              <a className="text-cyan-500 underline" href=" https://www.bnavi.tokyo/" target="_blank" rel="noreferrer">
                https://www.bnavi.tokyo/
              </a>
            </div>

          </div>
        </div>
      </>
  )
}

export default Client3;
