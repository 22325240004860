import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  CogIcon,
  LockClosedIcon,
  ServerIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const features = [
  {
    name: "MULTI PLATFORM",
    description: "ユーザービリティに配慮したUIに対応",
    icon: ArrowPathIcon,
  },
  {
    name: "OTHER INDUSTRIES COOPERATION",
    description: "他業種との連携するシステム開発",
    icon: CloudArrowUpIcon,
  },
  {
    name: "GLOBAL LANGUAGE",
    description: "日本語⇔英語⇔中国語など、多言語化対応",
    icon: CogIcon,
  },
  {
    name: "REDUCE THE BUDGET",
    description: "ご要望に応じての開発予算提案",
    icon: LockClosedIcon,
  },
  {
    name: "CONSULTING FROM PLANNING",
    description: "企画段階からのコンサルティング",
    icon: ServerIcon,
  },
  {
    name: "PROVISION OF RESOURCES",
    description: "必要に応じたリソースの提供。",
    icon: ShieldCheckIcon,
  },
];

const Section6 = () => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div data-aos="fade-up-right">
          <h2 className="text-lg font-semibold text-teal-500">柔軟な対応</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything You Need to Deploy Your App
          </p>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div
                  className="flow-root rounded-lg bg-gray-50 px-6 pb-8"
                  data-aos="zoom-out"
                >
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-teal-500 p-3 shadow-lg">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
