import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import bNavi from "../../assets/videos/Bnavi.mp4";

const Section5 = () => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  window.addEventListener(
    "load",
    function () {
      function playVideos(videoWrappers) {
        const startPosition = window.pageYOffset + window.innerHeight;
        for (let i = 0; i < videoWrappers.length; i++) {
          const videoPosition =
            videoWrappers[i].getBoundingClientRect().top + window.pageYOffset;
          if (startPosition > videoPosition) {
            const video = videoWrappers[i].getElementsByTagName("video");
            video[0].play();
          }
        }
      }
      const videoWrappers = document.getElementsByClassName("video_wrapper");
      if (videoWrappers.length) {
        playVideos(videoWrappers);
        window.addEventListener(
          "scroll",
          function () {
            playVideos(videoWrappers);
          },
          false
        );
      }
    },
    false
  );

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div
          className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8"
          data-aos="fade-right"
        >
          <div>
            <h2 className="text-lg font-semibold text-teal-500">アプリ紹介</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Benefit of this App
            </h3>
          </div>
        </div>
        <div className="mt-5 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none video_wrapper">
                  <video
                    className="rounded-lg object-cover object-center shadow-lg"
                    data-aos="zoom-in"
                    src={bNavi}
                    width={1184}
                    height={1376}
                    controls
                    muted
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0" data-aos="fade-right">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
                オリジナルパッケージを基準にクライアント様のご要望に応じてカスタマイズいたします。
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <a
                href="https://www.bnavi.tokyo/"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-teal-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 focus:ring-offset-2 sm:hover:scale-105 duration-300"
                >
                  （一般社団法人）健康増進開発機構さま
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
