import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import chats from "../../assets/images/chats.jpg";

const Section2 = () => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 600,
      easing: "ease",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div
          className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8"
          data-aos="fade-right"
        >
          <div>
            <h2 className="text-lg font-semibold text-teal-500">
              私たちの会社
            </h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Our Company
            </h3>
          </div>
        </div>
        <div className="mt-5 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div
              className="relative mx-auto max-w-prose text-base lg:max-w-none"
              data-aos="fade-left"
            >
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg contrast-75"
                    src={chats}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0" data-aos="fade-right">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
                常にシステムを操作するお客様の顔をイメージし、画面の向こうにはどのような方々がいるのか？
                を想像しながら構築を行っております。
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>
                実在する現場からの意見を取り入れたシステム作りの稼働率（システム稼働による生産性）は高く、業務管理との連携もワンストップで完結する仕様作りを行っております。
              </p>
              <br />
              <p>
                クローズド環境の構築やセキュリティ面にも配慮したシステム開発を得意としております。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
