const people = [
  {
    name: "佐々木　圭二",
    role: "ディレクター",
  },
  {
    name: "金内　好宏",
    role: "プロジェクトマネージャー",
  },
  {
    name: "佐々木　敬多",
    role: "フロントエンド / バックエンドエンジニア",
  },
  {
    name: "酒井　秀彰",
    role: "フロントエンドエンジニア",
  },
  {
    name: "大北　静",
    role: "Webデザイナー",
  },
  {
    name: "高橋　舞夏",
    role: "Webデザイナー",
  },
  {
    name: "ニアショア開発メンバー",
    role: "四国",
  },
  {
    name: "オフショア開発メンバー",
    role: "インド 弊社子会社",
  },
];

const OurTeam = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Our Team
            </h2>
            <p className="text-lg text-teal-500">
              弊社のメンバーをご紹介します。
            </p>
          </div>
          <div className="lg:col-span-2">
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-12 sm:space-y-0 lg:gap-x-8"
            >
              {people.map((person) => (
                <li key={person.name}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <div className="space-y-1 text-lg font-medium leading-6">
                      <h3>{person.name}</h3>
                      <p className="text-sm text-teal-600">{person.role}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
