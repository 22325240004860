import AlongTheLine from "./pages/AlongTheLine";
import OurTeam from "./pages/OurTeam";

import boss from "../assets/images/india.jpg";

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="bg-white py-16 lg:py-24">
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="relative overflow-hidden rounded-xl bg-teal-500 py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
            <div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter ">
              <img src={boss} alt="" className="h-full w-full object-cover" />
            </div>
            <div className="relative lg:col-span-1">
              <blockquote className="mt-6 text-white">
                <p className="text-xl sm:text-2xl">
                  「グローバルな視野を常に意識する。」
                  <br />
                  私達、日本人は世界各国から見てもとても治安も良く社会保障制度なども充実しています。しかし、守られた国だからこそ危機感を持たないと孤立平和難民化してしまいます。
                  先進国が故の安堵感に甘える事無く、チャレンジ精神を忘れず高度成長期のギラついた眼を、これから日本を背負って立つ若者に持ってもらえるような企業作りを致します。
                </p>
                <footer className="mt-6">
                  <p className="flex flex-col font-medium">
                    <span>メッセージ</span>
                    <span>代表取締役 佐々木 敬多</span>
                  </p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <OurTeam />

      <AlongTheLine />

      <div>
        <p className="mt-8 text-center text-base text-gray-400 underline underline-offset-8">
          株式会社 ADDIN
        </p>
        <p className="mt-8 text-center text-base text-gray-400">
          〒150-0002 東京都港区浜松町２丁目２番１５号 浜松町ダイヤビル２Ｆ :
          03-6771-9342
        </p>
        <p className="mt-8 text-center text-base text-gray-400">
          〒780-0027 高知県高知市愛宕山南町10-3（開発部）
        </p>
      </div>

      <div>
        <p className="mt-8 text-center text-base text-gray-400 underline underline-offset-8">
          INDIA Office
        </p>
        <p className="mt-8 text-center text-base text-gray-400">
          Office No. 318, Amanora Chambers (East), Level - C3, 4th Floor,
          Amanora Park Town, Magarpatta Road, Hadapsar, Pune, Maharashtra -
          411028 : +91 7821054373 (開発部)
        </p>
      </div>

      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-base text-gray-400">
            &copy; 株式会社 ADDIN
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
