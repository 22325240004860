import HeaderClient from "./HeaderClient";
import image from "../../../assets/images/client1.jpeg";

const messages = [
  {
    id:1,
    title: '-ホームページ制作を依頼された経緯を教えてください。',
    message: 'パーソナルジムの 2 店舗目オープンに伴い、新たなホームページ制作を検討したこと がきっかけです。元々、1 店舗目のホームページは、自分自身で簡易的なページを作成していたのですが、一から作るとなると手間もかかるし、今回はプロにお願いしようと思ったんです。そこで、以前から知り合いだった株式会社フットレストADDINの佐々木さんに依頼することになりました。',
  },
  {
    id:2,
    title: '-どのようなホームページを希望されていましたか?',
    message: '美容整骨師の資格を取得したので、それを生かして、新店舗はパーソナルジムと美容整骨をテーマとしていました。ですので、必然的に女性受けの良いイメージで考えていました。 佐々木さんには、「女性受けする」「明るい」「スタイリッシュ」といった簡単なイメージをお伝えしました。',
  },
  {
    id:3,
    title: '-実際に作成する前にはどのようなやり取りがありましたか?',
    message: '自分で思い描くものを言葉にして明確に伝えることが難しく、ざっくりとしたイメージ を伝えることしかできなかったのですが、佐々木さんからはそのイメージを踏まえて、 「こんなデザインにしてはどうか」「ここはこんな風にしてはどうか」など、具体的に色々な提案をしていただきました。予め議論できたおかげで、制作に入る前に自分が描く理想をある程度形にできたと思います。',
  },
  {
    id:4,
    title: '-発注から納品までのスケジュールはいかがでしたか?',
    message: '当初、打ち合わせの段階では制作日数は約２ヶ月と伺っていましたが、実際には納品まで １ヶ月弱という短期間で仕上げていただきました。 制作途中の校正などもマメに行っていただき、安心して進められました。',
  },
  {
    id:5,
    title: '-納品に至るまでで、大変だと感じたことはありましたか?',
    message: '発注側としては、大変な点は全くありませんでした。私自身、発注することが初めてで わからないことも多かったのですが、佐々木さんはこちらの目的を理解して色々な下調べをした上で、より良い提案をしてくださったので、悩むこともなく、また困ることも なく、スムーズに進められたと思います。 また、納品までの間に制作途中のものを都度確認させていただけて、修正についてもスピーディーに対応いただけたので、とても助かりました。',
  },
  {
    id:6,
    title: '-完成品とご自身のイメージバランスはいかがでしたか?',
    message: '質の良いサイトを作成していただけて、完成品には大変満足しています。 恐らく作業量も多く手間暇がかかっているのだと思いますが、佐々木さんからは、「要望があればいつでも言ってください」という言葉をいただき、実際にすぐに対応をしていただいていたので、安心してお任せできました。',
  },
  {
    id:7,
    title: '-全体を通して株式会社フットレストADD INの対応はいかがでしたか?',
    message: 'サイト制作の依頼は初めてで不安な点もありましたが、事前打ち合わせの段階から、こちらの要望を汲み取ってより良い提案をいただき、また改修の対応も素早く、とても気持ちのいいやり取りができました。 佐々木さんの提案で、サイト内に動画や画像を多く取り入れたこともあり、サイトオープン以来、これまでと異なる世代の方からの問い合わせも増えました。今風のスタイリッシュな雰囲気で、誰もが見やすい、という点が広く受け入れられたのかな、と思います。フットレストさんの提案がなければ気づけないようなポイントだったので、その効果を実感しています。 多くの方にこのサイトを見て足を運んでいただき、普段の生活を考えるきっかけを作れれば嬉しいです。 フットレストさんにはとても良いものを作っていただいたので、今後も機会があればまたお願いしたいと思いっています。',
  },
]

const Client1 = () => {
  return (
      <>
        <HeaderClient/>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-5xl text-lg">
            <h1>
              <span className="block text-center text-lg font-semibold text-teal-600">Introducing</span>
              <span
                  className="mt-2 block text-center text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
              Beginning fitness<br/>
                パーソナルトレーニング & ボディメイク & 美容整骨<br/>
                代表取締役 堀川 奨様
            </span>
            </h1>
            <p className="mt-8 text-center text-xl leading-8 text-gray-500">
              提案力と細やかな対応で生まれた信頼感
            </p>
            <p className="mt-8 text-md leading-8 text-gray-500">
              パーソナルトレーニングや美容整骨を通じて、健やかな体づくりをサポートしている 「Beginning fitness」。
              今回は株式会社フットレストADDINにホームページ制作を依頼された、代表の堀川奨さんにお話を聞きました。
            </p>
            <div className="flex justify-center mx-auto my-7 max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                      className="rounded-lg object-cover object-center shadow-lg max-w-xl"
                      src={image}
                      alt="Whitney leaning against a railing on a downtown street"
                  />
                </div>
              </figure>
            </div>

            {messages.map((message) => {
              return (
                  <div className="my-10" key={message.id}>
                    <p className='text-lg text-left leading-8 text-teal-600'>
                      {message.title}
                    </p>
                    <p className="mt-3 text-md text-left leading-8 text-gray-500">
                      {message.message}
                    </p>
                  </div>
              )
            })}

          </div>
        </div>
      </>

  )
}

export default Client1;
