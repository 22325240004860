import {Popover, Transition} from "@headlessui/react";
import logo from "../../assets/images/addin.png";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {Fragment} from "react";

const Header = (props) => {

  return (
      <header className="sticky top-0 bg-white z-10">
        <Popover className="relative bg-teal-white">
          <div
              className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <img
                    className="h-8 w-auto sm:h-10"
                    src={logo}
                    alt="Our Company"
                />
              </a>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button
                  className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              <button onClick={props.ScrollToAchievements}
                      className="text-base font-medium text-gray-500 hover:text-gray-900">
                開発実績例
              </button>
              <button onClick={props.ScrollToClient}
                      className="text-base font-medium text-gray-500 hover:text-gray-900">
                クライアント様の声
              </button>
              <button onClick={props.ScrollToFAQ}
                      className="text-base font-medium text-gray-500 hover:text-gray-900">
                よくある質問
              </button>
              <button onClick={props.ScrollToContact}
                      className="text-base font-medium text-gray-500 hover:text-gray-900">
                お問合せ
              </button>
            </Popover.Group>
          </div>

          <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
            >
              <div
                  className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                          className="h-8 w-auto"
                          src={logo}
                          alt="Our Company"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button
                          className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    <button onClick={props.ScrollToAchievements}
                            className="text-base font-medium text-gray-900 hover:text-gray-700">
                      開発実績例
                    </button>
                    <button onClick={props.ScrollToClient}
                            className="text-base font-medium text-gray-900 hover:text-gray-700">
                      クライアント様の声
                    </button>
                    <button onClick={props.ScrollToFAQ}
                            className="text-base font-medium text-gray-900 hover:text-gray-700">
                      よくある質問
                    </button>
                    <button onClick={props.ScrollToContact}
                            className="text-base font-medium text-gray-900 hover:text-gray-700">
                      お問合せ
                    </button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>
  )
}

export default Header;
